@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
.u-pt0 {
  padding-top: 0px; }

.u-pb0 {
  padding-bottom: 0px; }

.u-mt0 {
  margin-top: 0px; }

.u-mb0 {
  margin-bottom: 0px; }

.u-pt5 {
  padding-top: 5px; }

.u-pb5 {
  padding-bottom: 5px; }

.u-mt5 {
  margin-top: 5px; }

.u-mb5 {
  margin-bottom: 5px; }

.u-pt10 {
  padding-top: 10px; }

.u-pb10 {
  padding-bottom: 10px; }

.u-mt10 {
  margin-top: 10px; }

.u-mb10 {
  margin-bottom: 10px; }

.u-pt15 {
  padding-top: 15px; }

.u-pb15 {
  padding-bottom: 15px; }

.u-mt15 {
  margin-top: 15px; }

.u-mb15 {
  margin-bottom: 15px; }

.u-pt20 {
  padding-top: 20px; }

.u-pb20 {
  padding-bottom: 20px; }

.u-mt20 {
  margin-top: 20px; }

.u-mb20 {
  margin-bottom: 20px; }

.u-pt25 {
  padding-top: 25px; }

.u-pb25 {
  padding-bottom: 25px; }

.u-mt25 {
  margin-top: 25px; }

.u-mb25 {
  margin-bottom: 25px; }

.u-pt30 {
  padding-top: 30px; }

.u-pb30 {
  padding-bottom: 30px; }

.u-mt30 {
  margin-top: 30px; }

.u-mb30 {
  margin-bottom: 30px; }

.u-pt35 {
  padding-top: 35px; }

.u-pb35 {
  padding-bottom: 35px; }

.u-mt35 {
  margin-top: 35px; }

.u-mb35 {
  margin-bottom: 35px; }

.u-pt40 {
  padding-top: 40px; }

.u-pb40 {
  padding-bottom: 40px; }

.u-mt40 {
  margin-top: 40px; }

.u-mb40 {
  margin-bottom: 40px; }

.u-pt45 {
  padding-top: 45px; }

.u-pb45 {
  padding-bottom: 45px; }

.u-mt45 {
  margin-top: 45px; }

.u-mb45 {
  margin-bottom: 45px; }

.u-pt50 {
  padding-top: 50px; }

.u-pb50 {
  padding-bottom: 50px; }

.u-mt50 {
  margin-top: 50px; }

.u-mb50 {
  margin-bottom: 50px; }

.u-pt55 {
  padding-top: 55px; }

.u-pb55 {
  padding-bottom: 55px; }

.u-mt55 {
  margin-top: 55px; }

.u-mb55 {
  margin-bottom: 55px; }

.u-pt60 {
  padding-top: 60px; }

.u-pb60 {
  padding-bottom: 60px; }

.u-mt60 {
  margin-top: 60px; }

.u-mb60 {
  margin-bottom: 60px; }

.u-pt65 {
  padding-top: 65px; }

.u-pb65 {
  padding-bottom: 65px; }

.u-mt65 {
  margin-top: 65px; }

.u-mb65 {
  margin-bottom: 65px; }

.u-pt70 {
  padding-top: 70px; }

.u-pb70 {
  padding-bottom: 70px; }

.u-mt70 {
  margin-top: 70px; }

.u-mb70 {
  margin-bottom: 70px; }

.u-pt75 {
  padding-top: 75px; }

.u-pb75 {
  padding-bottom: 75px; }

.u-mt75 {
  margin-top: 75px; }

.u-mb75 {
  margin-bottom: 75px; }

.u-pt80 {
  padding-top: 80px; }

.u-pb80 {
  padding-bottom: 80px; }

.u-mt80 {
  margin-top: 80px; }

.u-mb80 {
  margin-bottom: 80px; }

.u-pt85 {
  padding-top: 85px; }

.u-pb85 {
  padding-bottom: 85px; }

.u-mt85 {
  margin-top: 85px; }

.u-mb85 {
  margin-bottom: 85px; }

.u-pt90 {
  padding-top: 90px; }

.u-pb90 {
  padding-bottom: 90px; }

.u-mt90 {
  margin-top: 90px; }

.u-mb90 {
  margin-bottom: 90px; }

.u-pt95 {
  padding-top: 95px; }

.u-pb95 {
  padding-bottom: 95px; }

.u-mt95 {
  margin-top: 95px; }

.u-mb95 {
  margin-bottom: 95px; }

.u-pt100 {
  padding-top: 100px; }

.u-pb100 {
  padding-bottom: 100px; }

.u-mt100 {
  margin-top: 100px; }

.u-mb100 {
  margin-bottom: 100px; }

#top .p-mainImg {
  width: 100%;
  height: 720px;
  background-color: #FAF8B7; }
  @media screen and (max-width: 1030px) {
    #top .p-mainImg {
      margin-bottom: 50px; } }
  @media screen and (max-width: 900px) {
    #top .p-mainImg {
      height: auto;
      padding: 30px 0 10px; } }
  #top .p-mainImg__inner {
    max-width: 1100px;
    margin: 0 auto;
    position: relative;
    height: 100%; }
    #top .p-mainImg__inner:after {
      content: '';
      background: url("/inc/image/top/pic_mainimg_view.png") no-repeat center/contain;
      width: 246px;
      height: 151px;
      display: block;
      position: absolute;
      bottom: 53px;
      right: 0; }
      @media screen and (max-width: 1280px) {
        #top .p-mainImg__inner:after {
          right: 20px;
          width: 200px;
          height: 123px; } }
      @media screen and (max-width: 1030px) {
        #top .p-mainImg__inner:after {
          display: none; } }
    #top .p-mainImg__inner .tax {
      text-align: right;
      font-size: 14px;
      position: absolute;
      bottom: 10px;
      right: 0; }
      @media screen and (max-width: 1024px) {
        #top .p-mainImg__inner .tax {
          bottom: 10px;
          right: 50%;
          transform: translateX(50%); } }
      @media screen and (max-width: 900px) {
        #top .p-mainImg__inner .tax {
          position: static;
          transform: none;
          text-align: center; } }
  #top .p-mainImg__catch {
    max-width: 670px;
    position: absolute;
    top: 134px;
    left: 0;
    z-index: 2;
    text-align: center; }
    @media screen and (max-width: 1130px) {
      #top .p-mainImg__catch {
        left: 20px; } }
    @media screen and (max-width: 1024px) {
      #top .p-mainImg__catch {
        max-width: 480px; } }
    @media screen and (max-width: 900px) {
      #top .p-mainImg__catch {
        position: static;
        max-width: 500px;
        margin: 0 auto 25px; } }
    @media screen and (max-width: 768px) {
      #top .p-mainImg__catch {
        padding: 0 20px; } }
    #top .p-mainImg__catch img {
      width: 100%;
      margin-bottom: -10px; }
    #top .p-mainImg__catch--pc {
      display: block; }
      @media screen and (max-width: 768px) {
        #top .p-mainImg__catch--pc {
          display: none; } }
    #top .p-mainImg__catch--sm {
      display: none; }
      @media screen and (max-width: 768px) {
        #top .p-mainImg__catch--sm {
          display: block; } }
    #top .p-mainImg__catch-sub {
      font-size: 22px;
      font-weight: 600;
      display: inline-block; }
      @media screen and (max-width: 1024px) {
        #top .p-mainImg__catch-sub {
          font-size: 18px; } }
      @media screen and (max-width: 768px) {
        #top .p-mainImg__catch-sub {
          font-size: 15px; } }
      @media screen and (max-width: 568px) {
        #top .p-mainImg__catch-sub {
          font-size: 14px; } }
  #top .p-mainImg__img {
    max-width: 685px;
    position: absolute;
    top: 30px;
    right: -58px;
    z-index: 1; }
    @media screen and (max-width: 1280px) {
      #top .p-mainImg__img {
        right: 20px; } }
    @media screen and (max-width: 1024px) {
      #top .p-mainImg__img {
        max-width: 545px; } }
    @media screen and (max-width: 900px) {
      #top .p-mainImg__img {
        position: static;
        max-width: 500px;
        margin: 0 auto 50px; } }
    @media screen and (max-width: 768px) {
      #top .p-mainImg__img {
        padding: 0 20px; } }
    #top .p-mainImg__img img {
      width: 100%; }
    #top .p-mainImg__img--pc {
      display: block; }
      @media screen and (max-width: 768px) {
        #top .p-mainImg__img--pc {
          display: none; } }
    #top .p-mainImg__img--sm {
      display: none; }
      @media screen and (max-width: 768px) {
        #top .p-mainImg__img--sm {
          display: block; } }
  #top .p-mainImg .c-cvArea {
    overflow: visible;
    padding: 0;
    background-color: transparent;
    position: absolute;
    bottom: 60px;
    left: 0;
    max-width: 773px;
    width: 100%; }
    @media screen and (max-width: 1130px) {
      #top .p-mainImg .c-cvArea {
        left: 20px; } }
    @media screen and (max-width: 1024px) {
      #top .p-mainImg .c-cvArea {
        max-width: 100%;
        bottom: 50px;
        left: 0; } }
    @media screen and (max-width: 900px) {
      #top .p-mainImg .c-cvArea {
        position: static;
        margin-bottom: 20px; } }
    @media screen and (max-width: 768px) {
      #top .p-mainImg .c-cvArea {
        max-width: 450px;
        margin: 0 auto -30px; } }
    #top .p-mainImg .c-cvArea__btns {
      margin-bottom: -15px;
      margin-left: -7.5px;
      margin-right: -7.5px; }
      @media screen and (max-width: 1030px) {
        #top .p-mainImg .c-cvArea__btns {
          margin: 0 -15px -30px; } }
      #top .p-mainImg .c-cvArea__btns > [class*="col-"] {
        padding: 0 7.5px 15px; }
        @media screen and (max-width: 1030px) {
          #top .p-mainImg .c-cvArea__btns > [class*="col-"] {
            padding: 0 15px 30px; } }
      @media screen and (max-width: 1024px) {
        #top .p-mainImg .c-cvArea__btns {
          width: 100%;
          margin: 0 auto -30px; } }
      @media screen and (max-width: 1024px) {
        #top .p-mainImg .c-cvArea__btns > li {
          margin-bottom: 5px; }
          #top .p-mainImg .c-cvArea__btns > li:last-child {
            margin-bottom: 0; } }
    #top .p-mainImg .c-cvArea__btn {
      width: 100%;
      padding: 30px 30px 30px 100px;
      font-size: 16px;
      background-color: #0EA523;
      color: #fff !important; }
      @media screen and (max-width: 1024px) {
        #top .p-mainImg .c-cvArea__btn {
          text-align: left; } }
      @media screen and (max-width: 568px) {
        #top .p-mainImg .c-cvArea__btn {
          font-size: 18px;
          padding: 23px 10px 13px 10px !important; } }
      #top .p-mainImg .c-cvArea__btn--present {
        padding-left: 115px; }
        @media screen and (max-width: 1024px) {
          #top .p-mainImg .c-cvArea__btn--present {
            padding-left: 180px; } }
        @media screen and (max-width: 768px) {
          #top .p-mainImg .c-cvArea__btn--present {
            padding-left: 125px; } }
        @media screen and (max-width: 568px) {
          #top .p-mainImg .c-cvArea__btn--present {
            padding-top: 20px !important;
            padding-bottom: 9px !important; } }
        #top .p-mainImg .c-cvArea__btn--present:before {
          width: 76px;
          height: 44px; }
          @media screen and (max-width: 568px) {
            #top .p-mainImg .c-cvArea__btn--present:before {
              width: 49px;
              height: 37px; } }
      #top .p-mainImg .c-cvArea__btn--demo {
        background-color: #13C713;
        padding-left: 95px; }
        @media screen and (max-width: 1024px) {
          #top .p-mainImg .c-cvArea__btn--demo {
            padding-left: 155px; } }
        @media screen and (max-width: 768px) {
          #top .p-mainImg .c-cvArea__btn--demo {
            padding-left: 102px; } }
        #top .p-mainImg .c-cvArea__btn--demo:before {
          width: 64px;
          height: 41px; }
          @media screen and (max-width: 568px) {
            #top .p-mainImg .c-cvArea__btn--demo:before {
              width: 48px;
              height: 30px; } }
        #top .p-mainImg .c-cvArea__btn--demo .c-cvArea__btn-sub {
          color: #13C713;
          border-color: #13C713; }
      #top .p-mainImg .c-cvArea__btn-sub {
        box-shadow: none;
        border: 2px solid #0EA523;
        font-size: 14px;
        padding: 4px 40px; }

#top .p-heading {
  font-size: 32px;
  text-align: center;
  margin-bottom: 30px; }
  @media screen and (max-width: 568px) {
    #top .p-heading {
      font-size: 24px;
      margin-bottom: 25px; } }
  #top .p-heading__sub {
    display: block;
    font-size: 23px;
    margin-bottom: 9px; }
    @media screen and (max-width: 568px) {
      #top .p-heading__sub {
        font-size: 18px; } }
    #top .p-heading__sub > span {
      display: inline; }

#top .p-point .p-heading {
  margin-bottom: 60px; }
  @media screen and (max-width: 568px) {
    #top .p-point .p-heading {
      margin-bottom: 30px; } }

#top .p-point__list {
  display: flex;
  justify-content: space-between;
  max-width: 893px;
  margin: 0 auto; }
  @media screen and (max-width: 680px) {
    #top .p-point__list {
      flex-direction: column; } }
  #top .p-point__list > li {
    min-width: 271px;
    position: relative; }
    @media screen and (max-width: 900px) {
      #top .p-point__list > li {
        min-width: 200px; } }
    @media screen and (max-width: 680px) {
      #top .p-point__list > li {
        min-width: 271px;
        margin-bottom: 50px; }
        #top .p-point__list > li:last-child {
          margin-bottom: 0; } }
    #top .p-point__list > li:before {
      content: '';
      width: 271px;
      height: 271px;
      background-color: #FAF8B7;
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1; }
      @media screen and (max-width: 900px) {
        #top .p-point__list > li:before {
          width: 200px;
          height: 200px; } }
      @media screen and (max-width: 680px) {
        #top .p-point__list > li:before {
          width: 271px;
          height: 271px; } }

#top .p-point__num {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  line-height: 1;
  position: relative;
  margin-bottom: 14px; }
  #top .p-point__num:before {
    content: '';
    background-color: #0EA523;
    width: 65px;
    height: 65px;
    display: block;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: -1; }
  #top .p-point__num > span {
    font-size: 30px;
    display: block; }

#top .p-point__txt {
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  line-height: 1.625;
  margin-bottom: 10px; }

#top .p-point__img {
  height: 150px; }
  @media screen and (max-width: 900px) {
    #top .p-point__img {
      height: 100px; } }
  @media screen and (max-width: 680px) {
    #top .p-point__img {
      height: 150px; } }
  #top .p-point__img img {
    display: block;
    height: 100%;
    margin: 0 auto; }

#top .p-solution {
  background-color: #FAF8B7;
  padding: 60px 0;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    #top .p-solution {
      padding: 50px 0; } }
  #top .p-solution .l-section {
    padding-top: 0;
    padding-bottom: 0; }
  #top .p-solution__txt {
    text-align: center; }
    @media screen and (max-width: 768px) {
      #top .p-solution__txt {
        margin-bottom: 0; } }
    @media screen and (max-width: 568px) {
      #top .p-solution__txt {
        text-align: left; } }
    @media screen and (max-width: 568px) {
      #top .p-solution__txt br {
        display: none; } }
  #top .p-solution__btn {
    text-align: center; }
    @media screen and (max-width: 768px) {
      #top .p-solution__btn--pc {
        display: none; } }
    #top .p-solution__btn--sp {
      display: none; }
      @media screen and (max-width: 768px) {
        #top .p-solution__btn--sp {
          display: block;
          margin-top: 30px; } }
  #top .p-solution__item {
    display: flex;
    align-items: flex-end;
    margin-bottom: 40px; }
    #top .p-solution__item:last-child {
      margin-bottom: 0; }
    #top .p-solution__item--left .p-solution__thumb {
      margin-right: 15px; }
    #top .p-solution__item--right {
      flex-direction: row-reverse; }
      #top .p-solution__item--right .p-solution__thumb {
        margin-left: 15px; }
    #top .p-solution__item p {
      font-weight: 600;
      background-color: #fff;
      padding: 20px;
      border-radius: 14px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  #top .p-solution__thumb {
    flex: 0 0 100px; }
    #top .p-solution__thumb img {
      display: block;
      width: 100%; }

#top .p-select {
  overflow: hidden; }
  @media screen and (max-width: 1030px) {
    #top .p-select {
      padding-top: 50px; } }
  #top .p-select .p-heading__sub .u-bdr--yellow > span {
    font-size: 40px; }
  #top .p-select__reason {
    font-size: 16px;
    font-weight: 600;
    line-height: 2;
    color: #333333;
    margin-bottom: -34px;
    z-index: 2; }
    #top .p-select__reason span:before {
      border-top-color: #FAF8B7; }
  #top .p-select__list {
    margin-bottom: -40px;
    margin-left: -20px;
    margin-right: -20px; }
    @media screen and (max-width: 1030px) {
      #top .p-select__list {
        margin: 0 -15px -30px; } }
    #top .p-select__list > [class*="col-"] {
      padding: 0 20px 40px; }
      @media screen and (max-width: 1030px) {
        #top .p-select__list > [class*="col-"] {
          padding: 0 15px 30px; } }
  #top .p-select__item > a {
    display: block; }
    #top .p-select__item > a:hover .c-link {
      color: #55D667; }
      #top .p-select__item > a:hover .c-link:before {
        left: 5px; }
  #top .p-select__item--support .p-select__rect img {
    max-width: 326px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: none; }
  #top .p-select__rect {
    width: 100%;
    height: 268px;
    background-color: #FAF8B7;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 15px; }
    #top .p-select__rect img {
      max-width: 244px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); }
  #top .p-select__link {
    text-align: center; }
    #top .p-select__link .c-link {
      display: inline-block; }

#top .p-price {
  background: url("/inc/image/common/bg/bg_obento-yellow.png") center/contain;
  background-size: 7.5%;
  padding: 30px 0;
  position: relative; }
  @media screen and (max-width: 1130px) {
    #top .p-price {
      background-size: 40%; } }
  @media screen and (max-width: 768px) {
    #top .p-price {
      padding-top: 92px; } }
  #top .p-price .l-section {
    padding-top: 0;
    padding-bottom: 0;
    position: relative; }
  #top .p-price__heading {
    font-size: 24px;
    text-align: center;
    margin-bottom: 30px; }
    @media screen and (max-width: 568px) {
      #top .p-price__heading {
        font-size: 22px; } }
    #top .p-price__heading--small {
      font-size: 20px;
      display: block; }
      @media screen and (max-width: 568px) {
        #top .p-price__heading--small {
          font-size: 18px; } }
    #top .p-price__heading--normal {
      font-size: inherit; }
      @media screen and (max-width: 568px) {
        #top .p-price__heading--normal {
          font-size: 18px; } }
    #top .p-price__heading--large {
      display: inline-block;
      position: relative; }
      @media screen and (max-width: 568px) {
        #top .p-price__heading--large {
          left: -15px; } }
      #top .p-price__heading--large:after {
        content: 'から';
        display: inline-block;
        font-size: 24px;
        position: absolute;
        bottom: 12px;
        right: -60px; }
        @media screen and (max-width: 568px) {
          #top .p-price__heading--large:after {
            font-size: 15px;
            right: -35px; } }
    #top .p-price__heading-inner {
      display: flex;
      justify-content: center;
      margin: 10px 0;
      position: relative; }
      #top .p-price__heading-inner > span {
        position: relative;
        width: 86px;
        height: 87px;
        background-color: #F37A11;
        display: block;
        border-radius: 6px;
        margin-right: 8px; }
        @media screen and (max-width: 680px) {
          #top .p-price__heading-inner > span {
            width: 60px;
            height: 61px; } }
        @media screen and (max-width: 568px) {
          #top .p-price__heading-inner > span {
            width: 56px;
            height: 56px; } }
        #top .p-price__heading-inner > span:last-child {
          margin-right: 0; }
        #top .p-price__heading-inner > span > span {
          font-size: 60px;
          color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
          @media screen and (max-width: 680px) {
            #top .p-price__heading-inner > span > span {
              font-size: 32px; } }
          @media screen and (max-width: 568px) {
            #top .p-price__heading-inner > span > span {
              font-size: 32px; } }
  #top .p-price__btn {
    text-align: center; }
  #top .p-price__circle {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    z-index: 1;
    position: absolute;
    top: -25px;
    left: 20%;
    transform: rotate(-15deg); }
    @media screen and (max-width: 900px) {
      #top .p-price__circle {
        left: 10%; } }
    @media screen and (max-width: 768px) {
      #top .p-price__circle {
        left: 20px;
        top: -80px; } }
    #top .p-price__circle:before {
      content: '';
      display: block;
      width: 98px;
      height: 98px;
      background-color: #0EA523;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      z-index: -1; }

#top .p-client {
  max-width: 100%;
  padding: 60px 0; }
  @media screen and (max-width: 768px) {
    #top .p-client {
      padding: 50px 0; } }
  #top .p-client__desc {
    text-align: center;
    margin-bottom: 30px;
    padding: 0 15px; }
    @media screen and (max-width: 568px) {
      #top .p-client__desc {
        text-align: left; }
        #top .p-client__desc br {
          display: none; } }
  #top .p-client__slider {
    background-image: url("/inc/image/top/img_clientLogo.png");
    background-size: auto 100%;
    background-repeat: repeat-x;
    width: 100%;
    height: 163px;
    animation: loop 60s linear infinite; }
    @media screen and (max-width: 568px) {
      #top .p-client__slider {
        animation: loop_sp 60s linear infinite;
        height: 130px; } }

@keyframes loop {
  0% {
    background-position: 0 0; }
  100% {
    background-position: -2560px 0; } }

@keyframes loop_sp {
  0% {
    transform: translateX(0px); }
  100% {
    background-position: calc( -1 * 2560px * (130 / 163)) 0; } }
